<template>
  <section class="section our-company" id="ourcompany" v-bind="initData">
    <div v-if="info">
      <section class="our-company-top">
        <img src="../../assets/img/Logo_Models.svg" alt="Be The Next Top Model" />
      </section>
      <section class="our-company-content" :style="`--coverDesktop: url(${info.ourCompanyCover});`">
        <div class="our-company-content-advice">
          <h2 class="our-company-content-advice-title">{{ info.ourCompanySubtitle }}</h2>
          <div class="our-company-content-advice-picture">
            <img :src="info.ourCompanyCoverMobile" loading="lazy" alt="our company" />
          </div>
          <div class="our-company-content-advice-text">
            <div>
              <div class="short">
                {{ getShort(removeHTMLEntities(info.text), $store.state.screen.width > 1440 ? 576 : 275) }}
                <router-link class="third-button button pointer desktop" :to="{ path: '/about' }" v-if="$store.state.screen.width > 1023">
                  {{ info.btnText }}
                </router-link>
              </div>

              <div class="long">{{ removeHTMLEntities(info.text) }}</div>
            </div>
            <div class="our-company-content-advice-options">
              <div class="our-company-content-advice-mobileBtns">
                <a class="third-button button pointer mbl" @click="toggleClass('.our-company-content-advice-text', 'expand')">
                  {{ info.btnText }}
                </a>
                <MagazineBtn :action="toggleMagazineModal" :text="info.ourCompanyBtnMagazine" class="our-company-content-advice-magazineBtn" />
              </div>
            </div>
            <div class="our-company-content-advice-btnContainer">
              <MagazineBtn :action="toggleMagazineModal" :text="info.ourCompanyBtnMagazine" class="magazineBtn" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-show="showModal" class="our-company__modalMagazine">
      <div class="our-company-modalContainer">
        <div :class="isLoading ? 'loading' : ''" id="react-root"></div>
      </div>
    </div>
  </section>
</template>

<script>
  import store from "../../store/index.js";
  import global from "@/assets/js/global.js";
  import { createRoot } from "react-dom/client";
  import React from "react";
  import Flipbook from "../Flipbook/Flipbook.jsx";
  import { EventBus } from "../../helpers/eventBus.js";
  import MagazineBtn from "../buttons/MagazineBtn.vue";
  export default {
    components: {
      MagazineBtn,
    },
    data: function () {
      return {
        info: null,
        showModal: false,
        reactRootInstance: null,
        isLoading: true,
      };
    },
    methods: {
      getLanguage: async function () {
        if (store.getters["iflanguage"]) {
          let dataLang = await store.getters["dataLang"];
          this.info = dataLang.ourCompany;
        }
      },
      getShort: (text, limit) => {
        return global.getShort(text, limit);
      },
      /**
       * Funcion encargada de reemplazar las entidades html por su representacion
       * @param {*} textoHTML
       */
      removeHTMLEntities: function (textoHTML) {
        const entidadesHTML = {
          "&lt;": "<",
          "&gt;": ">",
          "&amp;": "&",
          "&quot;": '"',
          "&apos;": "'",
          "&nbsp;": " ",
        };

        let textoLimpio = textoHTML;
        for (const entidad in entidadesHTML) {
          if (entidadesHTML.hasOwnProperty(entidad)) {
            const expresionRegular = new RegExp(entidad, "g");
            textoLimpio = textoLimpio.replace(expresionRegular, entidadesHTML[entidad]);
          }
        }
        return textoLimpio;
      },
      toggleClass: (elem, clss) => {
        document.querySelector(elem).classList.toggle(clss);
      },
      toggleMagazineModal() {
        this.showModal = !this.showModal;
        document.body.style.overflow = "";
        if (this.showModal) {
          document.body.style.overflow = "hidden";
          this.$nextTick(() => {
            this.renderReactComponent();
          });
        } else {
          document.body.style.overflow = "";
          this.removeReactInstance();
        }
      },
      renderReactComponent() {
        this.$nextTick(() => {
          if (!this.info) {
            console.error("La información de 'info' no está disponible.");
            return;
          }
          const container = document.getElementById("react-root");
          if (container) {
            if (!this.reactRootInstance) {
              this.reactRootInstance = createRoot(container);
            }
            this.reactRootInstance.render(
              <Flipbook
                closeFunction={this.toggleMagazineModal}
                translate={{
                  next: this.info.next,
                  previous: this.info.previous,
                  slide: this.info.slide,
                }}
                removeLoading={this.removeLoading}
              />
            );
          } else {
            console.error("El contenedor #react-root no está disponible en el DOM.");
          }
        });
      },
      removeLoading($event) {
        this.isLoading = !$event;
      },
      removeReactInstance() {
        if (this.reactRootInstance) {
          this.reactRootInstance.unmount();
          this.reactRootInstance = null;
        }
      },
    },
    computed: {
      initData: async function () {
        this.getLanguage();
      },
    },
    created() {
      EventBus.$on("toggle-magazine-modal", this.toggleMagazineModal);
    },
    mounted() {
      this.removeReactInstance();
      const { query, hash } = this.$route;
      const { openMagazine, ...newQuery } = query;

      if (openMagazine) {
        setTimeout(() => {
          this.$nextTick(() => {
            this.toggleMagazineModal();
          });
          this.$router.replace({ query: newQuery, hash });
        }, 300);
      }
    },
    beforeDestroy() {
      this.removeReactInstance();
      document.body.style.overflow = "";
      EventBus.$off("toggle-magazine-modal", this.toggleMagazineModal);
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .our-company {
    position: relative;
    &-top {
      @include Flex(row);
      padding: 25px 0;
    }
    &-content {
      position: relative;
      padding: 0 $mpadding;
      background-image: none;
      background-position: top center;
      background-size: cover;
      background-attachment: fixed;
      &-advice {
        padding: $mpadding * 2;
        position: relative;
        border-radius: $mradius;
        background: #eeeeee;
        margin: 0 auto;
        box-shadow: $dshadow;
        text-align: justify;
        line-height: 1.625;
        &-picture {
          display: block;
          img {
            margin: auto;
            max-width: 100%;
            border-radius: $mradius;
          }
        }
        &-title {
          display: none;
        }
        &-text {
          .long {
            display: none;
          }
          .short {
            position: relative;
            .third-button {
              position: absolute;
              bottom: -1px;
              right: 0px;
              padding: 0 6px;
              font-size: 14px;
              font-weight: 600;
              text-align: left;
              background: $white;
              color: $primary_color;
              transition: 0.3s ease-in-out;
              &:hover {
                border: none;
                color: #fff;
                background-color: $primary_color;
              }
            }
          }
          &.expand {
            .short,
            .button.mbl {
              display: none;
            }
            .long {
              display: block;
              > div p {
                margin: 0 0 $mpadding 0;
              }
            }
          }
        }
        &-magazineBtn {
          width: 150px;
          height: 40px;
          font-size: 15px;
        }
        &-btnContainer {
          display: none;
        }

        &-options {
          margin-top: $mpadding * 2;
          text-align: center;
          .button {
            color: $white;
            background-color: $primary_color;
            transition: 0.3s ease-in-out;
            border-radius: 20px;
            &:hover {
              border: solid 1px;
              color: $primary_color;
              background-color: #fff;
            }
            &.mbl {
              display: initial;
            }
            &.desktop {
              display: none;
            }
          }
        }
        &-mobileBtns {
          @include Flex(column);
          width: 100%;
          gap: $space-16;

          @media (min-width: 400px) {
            flex-direction: row;
          }
        }
        &-btn {
          display: none;
        }
      }
    }
    &__modalMagazine {
      @include Flex(column, center, center);
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      backdrop-filter: blur(7px);
      background: rgba(0, 0, 0, 0.58);
      z-index: 220;
    }
    @media screen and (min-width: 1025px) and (max-width: 1439px) {
      .our-company-content {
        background-image: var(--coverDesktop);
        background-attachment: fixed;
        background-position: center -150px;
        background-size: contain;
        &-advice {
          &-btnContainer {
            width: 70%;
            margin: auto;
            display: block;
            position: absolute;
            margin-left: 1%;
          }
          &-title {
            top: -75px;
            left: 17%;
            text-shadow: 1px 1px 1px #000;
          }
        }
      }
    }
    @media (min-width: $desktop_width) {
      &-content {
        min-height: 76vh;
        background-image: var(--coverDesktop);
        &-advice {
          position: absolute;
          background: transparent;
          box-shadow: 0 0 0;
          color: $white;
          top: 50%;
          right: 8%;
          transform: translate(5%, -50%);
          -ms-transform: translate(0, -50%);
          text-align: left;
          &-title {
            display: block;
            font-family: $sec_font;
            font-size: $space-38;
            font-style: normal;
            font-weight: 400;
            position: absolute;
            top: -75px;
            left: 17%;
            text-shadow: 1px 1px 1px #000;
          }
          &-picture {
            display: none;
          }
          &-text {
            width: 70%;
            margin: auto;
            &.expand {
              .short {
                display: block;
              }
              .long {
                display: none;
              }
            }
          }
          &-btnContainer {
            display: block;
            position: absolute;
            bottom: -16%;
          }
          &-options {
            text-align: left;
            .button {
              color: $primary_color;
              background-color: #fff;
              transition: 0.3s ease-in-out;
              &.mbl {
                display: none;
              }
              &.desktop {
                display: initial;
              }
              &:hover {
                border: none;
                color: #fff;
                background-color: $primary_color;
              }
            }
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            background-color: $primary_color_opacity;
            height: 90%;
            width: 78%;
            right: 12%;
            transform: skew(20deg);
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            background-color: $primary_color_opacity;
            height: 90%;
            width: 3%;
            right: 7%;
            transform: skew(20deg);
          }
          &-mobileBtns {
            display: none;
          }
        }
      }
    }
    &-modalContainer {
      @include Flex(column, center, center);
      position: relative;
      width: 100%;
      height: 100%;
    }
    @include Desktop {
      .our-company-content-advice {
        transform: translate(8%, -50%);
        max-width: 60%;
        &-text {
          width: 70%;
          margin: auto;
        }
        &::after {
          width: 72%;
          right: 15%;
        }
        &::before {
          right: 10%;
        }
      }
    }
    @media (min-width: $tv_width) {
      .our-company-content-advice {
        max-width: 50%;
        transform: translate(15%, -50%);
        &::after {
          width: 76%;
          right: 12%;
        }
        &::before {
          right: 7%;
        }
      }
    }
  }
  #react-root {
    @include Flex();
    width: 100%;
    height: 100%;
  }
  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity 0.3s ease;
  }
  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }
</style>
